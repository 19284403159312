<template>
  <div>
    <v-layout
      v-if="!editPersonalInformation"
      justify-center
    >
      <v-flex
        xs12
      >
        <v-card
          class="card-container"
        >
          <v-layout
            justify-space-between
          >
            <v-flex
              xs8
              align-self-start
              :class="$vuetify.breakpoint.smAndUp ? 'pl-4 mt-4' : 'ml-2 mt-3' "
            >
              <p class="text-card-title ml-3">
                Datos personales
              </p>
            </v-flex>
            <v-layout
              justify-end
            >
              <p
                v-if="$vuetify.breakpoint.smAndUp"
                class="editar"
                style="cursor: pointer"
                @click="editPersonalInformation = true"
              >
                <v-btn
                  style="margin-right: 0px"
                  icon
                >
                  <v-icon
                    icon
                    color="#EE8146"
                    size="18"
                  >
                    fas fa-pen
                  </v-icon>
                </v-btn>
                Editar
              </p>
              <v-btn
                v-else
                class="editar"
                icon
                @click="editPersonalInformation = true"
              >
                <v-icon
                  icon
                  color="#EE8146"
                  size="16"
                >
                  fas fa-pen
                </v-icon>
              </v-btn>
            </v-layout>
          </v-layout>
          <template
            v-if="userDetails.birth_date"
            :class="$vuetify.breakpoint.smAndUp ? 'ml-1 pl-5 ' : 'pl-4 ml-5 ' "
          >
            <div
              :class="$vuetify.breakpoint.smAndUp ? 'ml-1 pl-5 mt-4 ' : 'pl-4 mt-2' "
            >
              <span
                :class="$vuetify.breakpoint.smAndUp ? 'text-on-card' : 'text-on-card-responsive'"
              >
                {{ userDetails.birth_date }}
              </span>
              <p>Fecha de nacimineto</p>
            </div>
            <v-divider />
          </template>
          <template
            v-if="userDetails.gender"
          >
            <div
              :class="$vuetify.breakpoint.smAndUp ? 'ml-1 pl-5 mt-4' : 'pl-4 mt-2' "
            >
              <span
                :class="$vuetify.breakpoint.smAndUp ? 'text-on-card' : 'text-on-card-responsive'"
              >
                {{ getGender() }}
              </span>
              <p>Género</p>
            </div>
            <v-divider />
          </template>
          <template
            v-if="userDetails.nationality"
          >
            <div
              :class="$vuetify.breakpoint.smAndUp ? 'ml-1 pl-5 mt-4' : 'pl-4 mt-2' "
            >
              <span
                :class="$vuetify.breakpoint.smAndUp ? 'text-on-card' : 'text-on-card-responsive'"
              >
                {{ userDetails.nationality_name }}
              </span>
              <p>Nacionalidad</p>
            </div>
            <v-divider />
          </template>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout
      v-else
      justify-center
    >
      <v-flex
        xs12
      >
        <v-card>
          <v-card-title>
            <v-layout
              align-center
              justify-start
            >
              <v-flex
                :class="$vuetify.breakpoint.smAndUp ? 'pl-4 mt-4' : 'ml-2 mt-3' "
              >
                <p
                  class="text-card-title ml-3"
                >
                  Datos personales
                </p>
              </v-flex>
            </v-layout>
          </v-card-title>
          <v-layout
            align-center
            wrap
            justify-center
          >
            <v-flex
              xs12
              class="px-4"
            >
              <v-menu
                ref="dateMenu"
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <template
                  v-slot:activator="{ on }"
                  class=""
                >
                  <v-text-field
                    v-model="userDetails.birth_date"
                    label="Fecha de nacimiento"
                    readonly
                    class=""
                    outline
                    full-width
                    :error-messages="birthdayErrors"
                    append-icon="far fa-calendar"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="userDetails.birth_date"
                  :max="new Date().toISOString().substr(0, 10)"
                  min="1900-01-01"
                  @change="dateMenu = false"
                />
              </v-menu>
            </v-flex>
            <v-flex
              xs12
              class="px-4 "
            >
              <v-select
                v-model="userDetails.gender"
                :items="genders"
                label="Género"
                offset-y
                class=""
                outline
                hide-details
                full-width
              />
            </v-flex>
            <v-flex
              xs12
              class="px-4 my-4"
            >
              <CountryPicker
                v-model="userDetails.nationality"
                label="Nacionalidad"
                :clearable="false"
                class=""
                outline
                hide-details
                full-width
                :default-to-chile="false"
                :only-available="false"
                :error-messages="nationalityErrors"
              />
            </v-flex>
          </v-layout>
          <template
            v-if="$vuetify.breakpoint.smAndUp"
          >
            <v-layout
              justify-end
              mr-3
            >
              <v-btn
                color="info2"
                flat
                large
                @click="editPersonalInformation = false"
              >
                <p
                  class="btn-text-cancelar"
                >
                  Cancelar
                </p>
              </v-btn>
              <v-btn
                color="info2"
                style="border-radius: 6px;"
                large
                class="mb-3"
                @click="submitDetails(); editPersonalInformation = false"
              >
                <p
                  class="btn-text-guardar"
                >
                  Guardar
                </p>
              </v-btn>
            </v-layout>
          </template>
          <template
            v-else
          >
            <v-layout
              row
              wrap
              px-3
            >
              <v-flex
                xs12
              >
                <v-btn
                  color="info2"
                  flat
                  large
                  block
                  @click="editPersonalInformation = false"
                >
                  <p
                    class="btn-text-cancelar"
                  >
                    Cancelar
                  </p>
                </v-btn>
              </v-flex>
              <v-flex
                xs12
                mb-3
              >
                <v-btn
                  color="info2"
                  style="border-radius: 6px;"
                  large
                  block
                  @click="submitDetails(); editPersonalInformation = false"
                >
                  <p class="btn-text-guardar">
                    Guardar
                  </p>
                </v-btn>
              </v-flex>
            </v-layout>
          </template>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout
      v-if="!editContactInformation"
      justify-center
      class="mt-4"
    >
      <v-flex
        xs12
      >
        <v-card
          class="card-container"
        >
          <v-layout
            justify-space-between
          >
            <v-flex
              xs8
              :class="$vuetify.breakpoint.smAndUp ? 'pl-4 mt-4' : 'ml-2 mt-3' "
            >
              <p
                class="text-card-title ml-3"
              >
                Datos de contacto
              </p>
            </v-flex>
            <v-layout
              justify-end
            >
              <p
                v-if="$vuetify.breakpoint.smAndUp"
                class="editar"
                style="cursor: pointer"
                @click="editContact()"
              >
                <v-btn
                  style="margin-right: 0px"
                  icon
                >
                  <v-icon
                    icon
                    color="#EE8146"
                    size="18"
                  >
                    fas fa-pen
                  </v-icon>
                </v-btn>
                Editar
              </p>
              <v-btn
                v-else
                class="editar"
                icon
                @click="editContact()"
              >
                <v-icon
                  icon
                  color="#EE8146"
                  size="16"
                >
                  fas fa-pen
                </v-icon>
              </v-btn>
            </v-layout>
          </v-layout>
          <template>
            <template
              v-if="userDetails.city_name || userDetails.address"
            >
              <div
                :class="$vuetify.breakpoint.smAndUp ? 'ml-1 pl-5 mt-4' : 'pl-4 mt-3' "
              >
                <span
                  v-if="userDetails.address && !userDetails.city_name"
                  :class="$vuetify.breakpoint.smAndUp ? 'text-on-card' : 'text-on-card-responsive'"
                >
                  {{ userDetails.address }}
                </span>
                <span
                  v-if="userDetails.address && userDetails.city_name"
                  :class="$vuetify.breakpoint.smAndUp ? 'text-on-card' : 'text-on-card-responsive'"
                >
                  {{ userDetails.address }},    {{ city_name }},
                  {{ region_name }}, {{ GetcountryName(country) }}
                </span>
                <span
                  v-if="!userDetails.address && userDetails.city_name"
                  :class="$vuetify.breakpoint.smAndUp ? 'text-on-card' : 'text-on-card-responsive'"
                >
                  {{ city_name }}
                  {{ region_name }}, {{ GetcountryName(country) }}
                </span>
                <p>Dirección</p>
              </div>
              <v-divider />
            </template>
            <template
              v-if="userDetails.email"
            >
              <div
                :class="$vuetify.breakpoint.smAndUp ? 'ml-1 pl-5 mt-4' : 'pl-4 mt-3' "
              >
                <span
                  :class="$vuetify.breakpoint.smAndUp ? 'text-on-card' : 'text-on-card-responsive'"
                >
                  {{ userDetails.email }}
                </span>
                <p>Correo electrónico</p>
              </div>
              <v-divider />
            </template>
            <template
              v-if="userDetails.phone_number"
            >
              <div
                :class="$vuetify.breakpoint.smAndUp ? 'ml-1 pl-5 mt-4' : 'pl-4 mt-3' "
              >
                <span
                  :class="$vuetify.breakpoint.smAndUp ? 'text-on-card' : 'text-on-card-responsive'"
                >
                  {{ userDetails.phone_number }}
                </span>
                <p>Teléfono</p>
              </div>
              <v-divider />
            </template>
          </template>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout
      v-else
      justify-center
      class="mt-5"
    >
      <v-flex
        xs12
      >
        <v-card>
          <v-card-title>
            <v-layout
              align-center
              justify-start
            >
              <div
                class="pl-4 mt-3"
              >
                <p
                  class="text-card-title "
                >
                  Datos de contacto
                </p>
              </div>
            </v-layout>
          </v-card-title>
          <v-layout
            wrap
            justify-center
          >
            <v-flex
              xs12
              class="px-4"
            >
              <v-text-field
                v-model="address"
                label="Dirección"
                class=""
                outline
                full-width
                :error-messages="addressErrors"
              />
            </v-flex>
            <v-flex
              xs12
              class="px-4"
            >
              <CityPicker
                :key="region"
                v-model="city"
                :region="region"
                label="Ciudad o Comuna"
                class=""
                outline
                full-width
                :clearable="false"
                :error-messages="cityErrors"
              />
            </v-flex>
            <v-flex
              xs12
              class="px-4"
            >
              <RegionPicker
                :key="country"
                v-model="region"
                label="Región"
                class=""
                outline
                full-width
                :clearable="false"
                :country="country"
              />
            </v-flex>
            <v-flex
              xs12
              class="px-4"
            >
              <CountryPicker
                v-model="country"
                label="País de Residencia"
                class=""
                outline
                full-width
                :clearable="false"
                :default-to-chile="false"
              />
            </v-flex>
            <v-flex
              xs12
              class="px-4"
            >
              <v-text-field
                v-model="phone_number"
                label="Teléfono"
                type="tel"
                name="phone_number"
                :prefix="numberPrefix"
                autocomplete="phone_number"
                :error-messages="phoneErrors"
                class=""
                outline
                full-width
              />
            </v-flex>
            <v-flex
              xs12
              class="px-4"
            >
              <v-text-field
                v-model="email"
                label="Correo electrónico"
                class=""
                outline
                full-width
                :error-messages="emailErrors"
              />
            </v-flex>
          </v-layout>
          <template
            v-if="$vuetify.breakpoint.smAndUp"
          >
            <v-layout
              justify-end
              mr-3
            >
              <v-btn
                color="info2"
                flat
                large
                @click="editContactInformation = false;fieldErrors = null;"
              >
                <p
                  class="btn-text-cancelar"
                >
                  Cancelar
                </p>
              </v-btn>
              <v-btn
                color="info2"
                style="border-radius: 6px;"
                large
                class="mb-3"
                @click="submitDetailsContact()"
              >
                <p
                  class="btn-text-guardar"
                >
                  Guardar
                </p>
              </v-btn>
            </v-layout>
          </template>
          <template
            v-else
          >
            <v-layout
              row
              wrap
              px-3
            >
              <v-flex
                xs12
              >
                <v-btn
                  color="info2"
                  flat
                  large
                  block
                  @click="editContactInformation = false;fieldErrors = null;"
                >
                  <p class="btn-text-cancelar">
                    Cancelar
                  </p>
                </v-btn>
              </v-flex>
              <v-flex
                xs12
                mb-3
              >
                <v-btn
                  color="info2"
                  style="border-radius: 6px;"
                  large
                  block
                  @click="submitDetailsContact()"
                >
                  <p
                    class="btn-text-guardar"
                  >
                    Guardar
                  </p>
                </v-btn>
              </v-flex>
            </v-layout>
          </template>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from 'axios';
import CountryPicker from '@/components/inputs/CountryPicker.vue';
import RegionPicker from '@/components/inputs/RegionPicker.vue';
import CityPicker from '@/components/inputs/CityPicker.vue';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';

export default {
  name: 'UserDetails',
  components: {
    CountryPicker,
    CityPicker,
    RegionPicker,
  },
  mixins: [JWTConfigMixin],
  props: {
    userDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editPersonalInformation: false,
      editContactInformation: false,
      country: '',
      region: '',
      city: '',
      address: '',
      phone_number: '',
      region_name: '',
      email: '',
      regions: [],
      cities: [],
      city_name: '',
      dateMenu: false,
      genders: [
        { text: 'Masculino', value: 1 },
        { text: 'Femenino', value: 2 },
      ],
      fieldErrors: null,
    };
  },
  computed: {
    numberPrefix() {
      if (this.country === 'CL') {
        return '+56';
      } if (this.country === 'PE') {
        return '+51';
      } if (this.country === 'CO') {
        return '+57';
      }
      return '';
    },
    jointNumber() {
      return `${this.numberPrefix}${this.phone_number}`;
    },
    emailErrors() {
      return (this.fieldErrors ? this.fieldErrors.email : []);
    },
    birthdayErrors() {
      return (this.fieldErrors ? this.fieldErrors.birth_date : []);
    },
    nationalityErrors() {
      return (this.fieldErrors ? this.fieldErrors.nationality : []);
    },
    phoneErrors() {
      return (this.fieldErrors ? this.fieldErrors.phone_number : []);
    },
    addressErrors() {
      return (this.fieldErrors ? this.fieldErrors.address : []);
    },
    cityErrors() {
      return (this.fieldErrors ? this.fieldErrors.city : []);
    },
  },
  watch: {
    dateMenu(val) {
      if (val) {
        setTimeout(() => {
          this.$refs.picker.activePicker = 'YEAR';
        });
      }
    },
    userDetails() {
      this.setExtras();
    },
    region() {
      this.loadRegionName();
    },
    city() {
      this.loadCityName();
    },
  },
  mounted() {
    this.setExtras();
    this.loadRegionName();
    this.loadCityName();
  },
  methods: {
    GetcountryName(country) {
      const GetcountryNameTypeDict = {
        CL: 'Chile',
        PE: 'Perú',
        CO: 'Colombia',
      };
      return GetcountryNameTypeDict[country];
    },
    loadRegionName() {
      const endpoint = this.$store.state.endpoints.offers.public.regions;
      axios.get(endpoint, { params: { country: this.country } })
        .then(response => {
          this.regions = response.data;
          const region = this.regions.find(
            payload => payload.uuid === this.region,
          );
          this.region_name = region.name;
        });
    },
    loadCityName() {
      const endpoint = this.$store.state.endpoints.offers.public.cities;
      axios.get(endpoint, { params: { country: this.country } })
        .then(response => {
          this.cities = response.data;
          const city = this.cities.find(payload => payload.uuid === this.city);
          this.city_name = city.name;
        });
    },
    getGender() {
      const genderDict = {
        1: 'Masculino',
        2: 'Femenino',
      };
      return genderDict[this.userDetails.gender];
    },
    setExtras() {
      this.country = this.userDetails.city_region.country;
      this.region = this.userDetails.city_region.uuid;
      this.city = this.userDetails.city_uuid;
    },
    submitDetails() {
      this.fieldErrors = null;
      this.$store.commit('setLoading');
      const fd = new FormData();
      if (this.userDetails.birth_date) {
        fd.append('birth_date', this.userDetails.birth_date);
      }
      fd.append('nationality', this.userDetails.nationality);
      fd.append('gender', this.userDetails.gender);
      fd.append('email', this.userDetails.email);
      axios.patch(this.$store.state.endpoints.candidates.getSelf,
        fd, this.jwtConfig)
        .then(response => {
          this.$store.commit('updateToken', response.data.token);
          this.$store.commit('unsetLoading');
          this.$store.commit('adminSnackbarUp', 'Perfil editado con éxito.');
          this.$emit('input');
          axios.get(this.$store.state.endpoints.candidates.getLoginData, this.jwtConfig)
            .then(resp => {
              this.$store.commit('setLoginDetails', resp.data);
            });
        }).catch(err => {
          this.$store.commit('unsetLoading');
          this.fieldErrors = err.response.data;
        });
    },
    editContact() {
      this.address = this.userDetails.address;
      if (this.userDetails.phone_number) {
        this.phone_number = this.userDetails.phone_number.slice(3);
      } else {
        this.phone_number = '';
      }
      this.email = this.userDetails.email;
      this.editContactInformation = true;
    },
    submitDetailsContact() {
      this.fieldErrors = null;
      this.$store.commit('setLoading');
      const fd = new FormData();
      fd.append('email', this.email);
      fd.append('city', this.city);
      if (this.phone_number !== '') {
        fd.append('phone_number', this.jointNumber);
      } else {
        fd.append('phone_number', this.phone_number);
      }
      fd.append('address', this.address);
      axios.patch(this.$store.state.endpoints.candidates.getSelf,
        fd, this.jwtConfig)
        .then(response => {
          this.$store.commit('updateToken', response.data.token);
          this.$store.commit('unsetLoading');
          this.$store.commit('adminSnackbarUp', 'Perfil editado con éxito.');
          this.$emit('input');
          this.editContactInformation = false;
          axios.get(this.$store.state.endpoints.candidates.getLoginData, this.jwtConfig)
            .then(resp => {
              this.$store.commit('setLoginDetails', resp.data);
            });
        }).catch(err => {
          this.$store.commit('unsetLoading');
          this.fieldErrors = err.response.data;
          this.editContactInformation = true;
          console.log('details contacts', this.fieldErrors);
        });
    },
  },
};
</script>
<style scoped>
.card-container {
  border-radius: 6px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.11);
}
.text-card-title {
  height: 32px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: 8px;
}
.text-on-card {
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.15px;
  line-height: 32px;
  margin: 0px;
}
.text-on-card-responsive {
  height: 40px;
  color: rgba(0,0,0,0.87);
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.1px;
  line-height: 20px;
}
.btn-text-cancelar {
  color: #30B0BF;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.3px;
  line-height: 24px;
  text-align: center;
  margin-top: 15px;
  text-transform: initial;
}
.btn-text-guardar {
  color: #FFF0E8;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.3px;
  line-height: 24px;
  text-align: center;
  margin-top: 15px;
  text-transform: initial;
}
.editar {
  color: #EE8146;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.3px;
  line-height: 20px;
  margin-right: 25px;
  margin-top: 18px;
  position: absolute;
}
.inputs-profile {
  /* This is not Used */
  height: 42px;
  border: 1px solid rgba(0,0,0,0.23);
  border-radius: 4px;
  margin-top: 20px;
  padding-bottom: 60px;
}
</style>
