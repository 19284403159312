<template>
  <v-select
    v-model="pickerValue"
    :disabled="industries.length < 1"
    :items="industries"
    item-text="text"
    item-value="value"
    hide-selected
    label="Rubro"
    class="inputs-profile"
    full-width
    clearable
    v-bind="$attrs"
    nudge-bottom="60"
  >
    <template v-slot:no-data>
      <v-list-tile>
        <v-list-tile-content>
          <v-list-tile-title>
            No se encuentran resultados
          </v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
    </template>
  </v-select>
</template>

<script>
import axios from 'axios';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';

export default {
  name: 'IndustriesPicker',
  mixins: [JWTConfigMixin],
  props: {
    value: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      pickerValue: '',
      industries: [],
      loading: false,
    };
  },
  watch: {
    pickerValue() {
      this.$emit('input', this.pickerValue);
    },
    value(newVal) {
      this.pickerValue = newVal;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.pickerValue = this.value;
      this.loadIndustries();
    });
  },
  methods: {
    loadIndustries() {
      this.loading = true;
      axios.get(this.$store.state.endpoints.candidates.industries, this.jwtConfig)
        .then(resp => {
          this.industries = resp.data;
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.inputs-profile {
  height: 42px;
  border: 1px solid rgba(0,0,0,0.23);
  border-radius: 4px;
  margin-top: 20px;
  padding-bottom: 60px;
}
</style>
