<template>
  <v-container
    style="max-width: 820px"
  >
    <v-layout>
      <v-flex
        xs12
      >
        <p
          :class="$vuetify.breakpoint.smAndUp
            ? 'my-profile' : 'my-profile-responsive'"
        >
          Mi Perfil Profesional
        </p>
        <div
          v-if="!userDetails.cv"
          class="notification-cv"
        >
          <p
            :class="$vuetify.breakpoint.smAndUp
              ? 'title-text-notification-cv' : 'title-text-notification-cv-responsive'"
          >
            ¡Recuerda que para postular tienes que subir tu Cv!
          </p>
          <p
            :class="$vuetify.breakpoint.smAndUp
              ? 'subtitle-text-notification-cv' : 'subtitle-text-notification-cv-responsive'"
          >
            Más empresas van a estar interesados en ti si rellenas tu perfil
          </p>
        </div>
      </v-flex>
    </v-layout>
    <v-layout mt-4>
      <v-flex
        xs12
      >
        <v-card
          class="card-profile"
        >
          <v-layout
            justify-end
          >
            <p
              v-if="$vuetify.breakpoint.smAndUp"
              class="editar"
              style="cursor: pointer"
              @click="editProfile()"
            >
              <v-btn
                style="margin-right: 0"
                icon
              >
                <v-icon
                  icon
                  color="#EE8146"
                  size="18"
                >
                  fas fa-pen
                </v-icon>
              </v-btn>
              Editar
            </p>
            <v-btn
              v-else
              class="editar"
              icon
              @click="editProfile()"
            >
              <v-icon
                icon
                color="#EE8146"
                size="18"
              >
                fas fa-pen
              </v-icon>
            </v-btn>
          </v-layout>
          <v-layout
            justify-center
            mt-5
          >
            <v-badge
              overlap
              color="white"
              bottom
            >
              <template v-slot:badge>
                <v-icon
                  size="23"
                  color="#EE8146"
                  @click="$refs.pfpFileField.click()"
                >
                  fas fa-camera
                </v-icon>
              </template>
              <v-avatar size="88">
                <v-img :src="userDetails.profile_pic ? userDetails.profile_pic : 'https://avataaars.io/'">
                  <input
                    ref="pfpFileField"
                    type="file"
                    style="display: none"
                    accept="*"
                    @change="onPfpPicked"
                  >
                </v-img>
              </v-avatar>
            </v-badge>
          </v-layout>
          <template v-if="edit_professional_profile === false">
            <v-layout justify-center>
              <p
                :class="$vuetify.breakpoint.smAndUp
                  ? 'candidate-name' : 'candidate-name-responsive'"
              >
                {{ userDetails.first_name }} {{ userDetails.last_name }}
              </p>
            </v-layout>
            <v-layout justify-center>
              <p
                :class="$vuetify.breakpoint.smAndUp
                  ? 'candidate-title' : 'candidate-title-responsive'"
              >
                {{ userDetails.heading }}
              </p>
            </v-layout>
            <v-layout
              justify-center
            >
              <template v-if="$vuetify.breakpoint.smAndUp">
                <a
                  v-if="userDetails.linkedin"
                  class="candidate-annexes"
                  style="cursor: pointer;text-decoration: none"
                  :href="userDetails.linkedin"
                  target="_blank"
                >
                  <v-btn
                    style="margin-right: 0px"
                    icon
                  >
                    <v-icon
                      icon
                      color="#EE8146"
                      size="17"
                    >
                      fab fa-linkedin-in
                    </v-icon>
                  </v-btn>
                  LinkedIn
                </a>
                <p
                  v-else
                  class="candidate-annexes"
                  style="cursor: pointer"
                >
                  <v-btn
                    style="margin-right: 0px"
                    icon
                  >
                    <v-icon
                      icon
                      color="#EE8146"
                      size="17"
                    >
                      fab fa-linkedin-in
                    </v-icon>
                  </v-btn>
                  LinkedIn
                </p>
                <a
                  class="candidate-annexes"
                  style="cursor: pointer;text-decoration: none"
                  :href="userDetails.cv"
                  :download="`${userDetails.cvName}.pdf`"
                  target="_blank"
                >
                  <v-btn
                    style="margin-right: 0px"
                    icon
                  >
                    <v-icon
                      icon
                      color="#EE8146"
                      size="17"
                    >
                      fas fa-arrow-alt-to-bottom
                    </v-icon>
                  </v-btn>
                  Descargar CV
                </a>
              </template>
              <template v-else>
                <v-btn
                  v-if="userDetails.linkedin"
                  style="margin-right: 0px"
                  :href="userDetails.linkedin"
                  target="_blank"
                  icon
                >
                  <v-icon
                    icon
                    color="#EE8146"
                    size="17"
                  >
                    fab fa-linkedin-in
                  </v-icon>
                </v-btn>
                <v-btn
                  v-else
                  style="margin-right: 0px"
                  icon
                >
                  <v-icon
                    icon
                    color="#EE8146"
                    size="17"
                  >
                    fab fa-linkedin-in
                  </v-icon>
                </v-btn>
                <v-btn
                  style="margin-right: 0px"
                  :href="userDetails.cv"
                  target="_blank"
                  :download="`${userDetails.cvName}.pdf`"
                  icon
                >
                  <v-icon
                    icon
                    color="#EE8146"
                    size="17"
                  >
                    fas fa-arrow-alt-to-bottom
                  </v-icon>
                </v-btn>
              </template>
              <p
                v-if="false"
                class="candidate-annexes"
                style="cursor: pointer"
              >
                <v-btn
                  style="margin-right: 0px"
                  icon
                >
                  <v-icon
                    icon
                    color="#EE8146"
                    size="17"
                  >
                    far fa-clone
                  </v-icon>
                </v-btn>
                Curriculum
              </p>
            </v-layout>
          </template>
          <template v-else>
            <v-container>
              <v-layout
                row
                wrap
                mt-4
              >
                <v-flex xs12>
                  <v-text-field
                    v-model="first_name"
                    label="Nombre"
                    class="inputs-profile"
                    :error-messages="firstNameError"
                    full-width
                  />
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="last_name"
                    label="Apellidos"
                    class="inputs-profile"
                    :error-messages="lastNameError"
                    full-width
                  />
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="heading"
                    label="Título bajada"
                    class="inputs-profile"
                    hide-details
                    full-width
                  />
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="linkedin"
                    label="Link Linkedin"
                    class="inputs-profile"
                    :error-messages="linkedinError"
                    full-width
                  />
                </v-flex>
                <v-layout
                  align-center
                  justify-center
                  column
                  :class="cvError ? 'upload-cv-error' : 'upload-cv'"
                  @click="pickCvFile"
                >
                  <v-flex
                    xs6
                    mt-4
                    style="height: 20px"
                  >
                    <v-btn
                      style="margin: 0px"
                      icon
                    >
                      <v-icon
                        icon
                        :color="cvError ? '#FF7573' : '#30B0BF'"
                        size="21"
                      >
                        far fa-clone
                      </v-icon>
                    </v-btn>
                  </v-flex>
                  <v-flex xs6>
                    <p
                      v-if="!cvName"
                      :class="cvError ? 'upload-cv-text-error' : 'upload-cv-text'"
                      style="cursor: pointer;"
                    >
                      Subir curriculum
                    </p>
                    <p
                      v-else
                      class="upload-cv-text"
                      style="cursor: pointer;"
                    >
                      {{ cvName }}
                    </p>
                    <input
                      ref="cvFileField"
                      type="file"
                      style="display: none; cursor: pointer;"
                      accept="*"
                      @change="onCvFilePicked"
                    >
                  </v-flex>
                </v-layout>
              </v-layout>
              <v-layout
                v-if="$vuetify.breakpoint.smAndUp"
                row
                justify-space-between
              >
                <v-flex
                  v-if="cvError"
                  xs12
                >
                  <p
                    class="formnat-cv-error"
                  >
                    Debe subir un CV.
                  </p>
                </v-flex>
                <v-flex
                  xs12
                >
                  <p
                    class="formnat-cv"
                  >
                    Los formatos permitidos son: JPG y PNG peso max 2mb
                  </p>
                </v-flex>
              </v-layout>
              <v-layout
                v-else
                row
                wrap
                justify-start
              >
                <v-flex
                  v-if="cvError"
                  xs12
                >
                  <p
                    class="formnat-cv-responsive-error"
                  >
                    Debe subir un CV.
                  </p>
                </v-flex>
                <v-flex
                  xs12
                >
                  <p
                    class="formnat-cv-responsive"
                  >
                    Los formatos permitidos son: JPG y PNG peso max 2mb
                  </p>
                </v-flex>
              </v-layout>
              <template v-if="$vuetify.breakpoint.smAndUp">
                <v-layout justify-end>
                  <v-btn
                    color="#30B0BF"
                    flat
                    large
                    @click="cancelEditProfile()"
                  >
                    <p class="cancel-text">
                      Cancelar
                    </p>
                  </v-btn>
                  <v-btn
                    color="#30B0BF"
                    style="border-radius: 6px"
                    large
                    @click="submitDetails()"
                  >
                    <p class="save-text">
                      Guardar
                    </p>
                  </v-btn>
                </v-layout>
              </template>
              <template v-else>
                <v-layout
                  row
                  wrap
                >
                  <v-flex xs12>
                    <v-btn
                      color="#30B0BF"
                      flat
                      large
                      block
                      @click="cancelEditProfile()"
                    >
                      <p class="cancel-text">
                        Cancelar
                      </p>
                    </v-btn>
                  </v-flex>
                  <v-flex xs12>
                    <v-btn
                      color="#30B0BF"
                      style="border-radius: 6px"
                      large
                      block
                      @click="submitDetails()"
                    >
                      <p class="save-text">
                        Guardar
                      </p>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </template>
            </v-container>
          </template>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout
      mt-4
    >
      <v-flex
        xs12
      >
        <v-card
          class="card-percentage-profile"
          elevation="0"
        >
          <v-container
            style="max-width: 720px;margin-top: 10px"
          >
            <p
              v-if="$vuetify.breakpoint.smAndUp"
              class="title-percentage"
            >
              Tu perfil está a un {{ Math.round(userDetails.profile_percentage) }} %
            </p>
            <p
              v-else
              class="title-percentage"
            >
              Estado de tu perfil
            </p>
            <p
              :class="$vuetify.breakpoint.smAndUp
                ? 'sub-title-percentage' : 'sub-title-percentage-responsive'"
            >
              Completa tus datos para que puedas rápidamente postular a las ofertas de empleo
            </p>
            <v-layout
              justify-center
            >
              <v-progress-linear
                v-if="$vuetify.breakpoint.smAndUp"
                v-model="userDetails.profile_percentage"
                class="progress-percentege"
                color="#EE8146"
                background-color="#EDECE9"
                :height="10"
              />
              <v-progress-circular
                v-else
                v-model="$store.state.profile_percentage"
                :width="20"
                :rotate="180"
                :size="177"
                color="#EE8146"
              >
                <div
                  class="number-percentage-responsive"
                >
                  {{ Math.round($store.state.profile_percentage) }}%
                </div>
              </v-progress-circular>
            </v-layout>
            <v-layout
              v-if="$vuetify.breakpoint.smAndUp"
              justify-space-between
            >
              <p class="number-percentage">
                0 % completado
              </p>
              <p class="number-percentage">
                100% completado
              </p>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout
      mt-4
    >
      <v-flex
        v-if="userDetails"
        xs12
      >
        <UserDetails
          :user-details="userDetails"
          @input="loadSelf()"
        />
      </v-flex>
    </v-layout>
    <CandidateHasWorkExperience
      :work-experience="workExperience"
      @input="loadSelf"
    />
    <CandidateHasDegree
      :chd-list="chdList"
      :cand-country="userDetails.city_region.country"
      @input="loadSelf"
    />
  </v-container>
</template>

<script>
import axios from 'axios';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';
import UserDetails from '@/components/candidates/UserDetails.vue';
import CandidateHasDegree from '@/components/candidates/CandidateHasDegree.vue';
import CandidateHasWorkExperience from '@/components/candidates/CandidateHasWorkExperience.vue';

export default {
  name: 'Profile',
  components: {
    CandidateHasWorkExperience,
    UserDetails,
    CandidateHasDegree,
  },
  mixins: [JWTConfigMixin],
  data() {
    return {
      userDetails: null,
      overlay: false,
      currentTab: 'tab1',
      pfpFile: null,
      chdList: null,
      edit_professional_profile: false,
      country: '',
      region: '',
      cvName: '',
      first_name: '',
      last_name: '',
      heading: '',
      linkedin: '',
      cv: '',
      workExperience: [],
      fieldErrors: null,
    };
  },
  computed: {
    cvError() {
      return (this.fieldErrors ? this.fieldErrors.cv : false);
    },
    firstNameError() {
      return (this.fieldErrors ? this.fieldErrors.first_name : []);
    },
    lastNameError() {
      return (this.fieldErrors ? this.fieldErrors.last_name : []);
    },
    linkedinError() {
      return (this.fieldErrors ? this.fieldErrors.linkedin : []);
    },
  },
  watch: {
    userDetails() {
      this.setExtras();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadSelf();
      this.setExtras();
    });
  },
  methods: {
    setExtras() {
      this.country = this.userDetails.city_region.country;
      this.region = this.userDetails.city_region.uuid;
      const pathArr = this.userDetails.cv.split('/');
      this.cvName = pathArr[pathArr.length - 1].split('?').shift();
    },
    onCvFilePicked(e) {
      this.cv = e.target.files[0] !== undefined ? e.target.files[0] : null;
      this.cvName = e.target.files[0] !== undefined ? e.target.files[0].name : '';
    },
    pickCvFile() {
      this.$refs.cvFileField.click();
    },
    onPfpPicked(e) {
      this.pfpFile = e.target.files[0] !== undefined ? e.target.files[0] : null;
      const fd = new FormData();
      fd.append('profile_pic', this.pfpFile);
      axios.put(this.$store.state.endpoints.candidates.getSelf,
        fd, this.jwtConfig)
        .then(resp => {
          this.$store.commit('adminSnackbarUp', resp.data);
          this.loadSelf();
        }).catch(err => {
          this.$store.commit('adminSnackbarUp', err.response.data.profile_pic[0]);
        });
    },
    loadSelf() {
      this.$store.commit('setLoading');
      axios.get(this.$store.state.endpoints.candidates.getSelf,
        this.jwtConfig)
        .then(resp => {
          axios.get(this.$store.state.endpoints.candidates.getCHD,
            this.jwtConfig)
            .then(respo => {
              this.chdList = respo.data;
              this.userDetails = resp.data;
              this.$store.commit('unsetLoading');
            });
          axios.get(this.$store.state.endpoints.candidates.workExperience, this.jwtConfig)
            .then(respo => {
              this.workExperience = respo.data;
            });
        })
        .catch(() => {
          this.$router.push({ name: 'candidate:offers' });
        });
    },
    editProfile() {
      this.first_name = this.userDetails.first_name;
      this.last_name = this.userDetails.last_name;
      this.heading = this.userDetails.heading;
      this.linkedin = this.userDetails.linkedin;
      this.cv = this.userDetails.cv;
      if (this.userDetails.cv) {
        const pathArr = this.userDetails.cv.split('/');
        this.cvName = pathArr[pathArr.length - 1].split('?').shift();
      }
      this.edit_professional_profile = true;
    },
    cancelEditProfile() {
      this.first_name = '';
      this.last_name = '';
      this.heading = '';
      this.linkedin = '';
      this.cv = '';
      this.cvName = '';
      this.edit_professional_profile = false;
      this.fieldErrors = null;
    },
    submitDetails() {
      this.fieldErrors = null;
      this.$store.commit('setLoading');
      const fd = new FormData();
      fd.append('notification_consent', this.userDetails.notification_consent);
      fd.append('marketing_notification_consent', this.userDetails.marketing_notification_consent);
      fd.append('first_name', this.first_name);
      fd.append('last_name', this.last_name);
      fd.append('heading', this.heading);
      fd.append('linkedin', this.linkedin);
      fd.append('email', this.userDetails.email);
      if (!(typeof this.cv === 'string')) {
        fd.append('cv', this.cv);
      }
      axios.patch(this.$store.state.endpoints.candidates.getSelf,
        fd, this.jwtConfig)
        .then(() => {
          this.$store.commit('unsetLoading');
          this.$store.commit('adminSnackbarUp', 'Perfil editado con éxito.');
          this.cancelEditProfile();
          this.loadSelf();
          axios.get(this.$store.state.endpoints.candidates.getLoginData, this.jwtConfig)
            .then(resp => {
              this.$store.commit('setLoginDetails', resp.data);
            });
        }).catch(err => {
          this.$store.commit('unsetLoading');
          this.fieldErrors = err.response.data;
          console.log('TYPE CVERROR', typeof this.cvError);
          console.log('CVERROR', this.cvError);
        });
    },
  },
};
</script>

<style scoped>
.my-profile{
  height: 42px;
  width: 320px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 34px;
  font-weight: 900;
  letter-spacing: 0.25px;
  line-height: 42px;
  margin-bottom: 25px;
}
.my-profile-responsive {
  height: 32px;
  width: 292px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 32px;
}
.notification-cv {
  height: 67px;
  width: auto;
  border-radius: 6px;
  background-color: rgba(48,176,191,0.1);
  margin-bottom: 24px;
}
.title-text-notification-cv {
  height: 10px;
  color: #30B0BF;
  font-family: Lato;
  font-weight: bolder;
  font-size: 20px;
  letter-spacing: 0.14px;
  text-align: center;
  padding-top: 10px;
}
.title-text-notification-cv-responsive {
  color: #30B0BF;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.1px;
  text-align: center;
  padding-top: 10px;
  margin: 0px;
}
.subtitle-text-notification-cv {
  color: #30B0BF;
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0.14px;
  text-align: center;
  padding-top: 10px;
}
.subtitle-text-notification-cv-responsive {
  color: #30B0BF;
  font-family: Lato;
  font-size: 11px;
  letter-spacing: 0.14px;
  text-align: center;
  padding-top: 5px;
}
.card-profile{
  height: auto;
  width: auto;
  border-radius: 6px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.11);
}
.editar {
  color: #EE8146;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.3px;
  line-height: 20px;
  margin-right: 25px;
  margin-top: 30px;
  position: absolute;
}
.candidate-name {
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 23px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 62px;
  margin: 0px;
  height: 45px;
}
.candidate-name-responsive {
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 23px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  margin: 0px;
  margin-top: 10px;
}
.candidate-title {
  color: rgba(0,0,0,0.6);
  font-family: Lato;
  font-size: 24px;
  letter-spacing: 0;
}
.candidate-title-responsive {
  color: rgba(0,0,0,0.6);
  font-family: Lato;
  font-size: 17px;
  letter-spacing: 0;
  line-height: 32px;
}
.candidate-annexes {
  height: 32px;
  color: #EE8146;
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.15px;
  line-height: 32px;
  margin: 20px;
  margin-bottom: 50px;
}
.inputs-profile {
  height: 42px;
  border: 1px solid rgba(0,0,0,0.23);
  border-radius: 4px;
  margin-top: 20px;
  padding-bottom: 60px;
}
.upload-cv {
  box-sizing: border-box;
  height: 110px;
  width: auto;
  border: 1px solid #30B0BF;
  border-radius: 6px;
  background-color: rgba(48,176,191,0.07);
  margin-top: 20px;
  cursor: pointer;
}
.upload-cv-error {
  box-sizing: border-box;
  height: 110px;
  width: auto;
  border: 1px solid #FF7573;
  border-radius: 6px;
  background-color: rgba(191, 48, 48, 0.07);
  margin-top: 20px;
  cursor: pointer;
}
.upload-cv-text {
  height: 22px;
  color: #30B0BF;
  font-family: Lato;
  font-size: 15.5px;
  font-weight: bold;
  letter-spacing: 0.1px;
  line-height: 25px;
  margin-left: 12px;
  cursor: pointer;
}
.upload-cv-text-error {
  height: 22px;
  color: #FF7573;
  font-family: Lato;
  font-size: 15.5px;
  font-weight: bold;
  letter-spacing: 0.1px;
  line-height: 25px;
  margin-left: 12px;
  cursor: pointer;
}
.formnat-cv {
  height: 20px;
  color: rgba(0,0,0,0.30);
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0.11px;
  line-height: 24px;
  text-align: right;
}
.formnat-cv-error {
  height: 20px;
  color: #FF7573;
  font-family: Lato;
  font-size: 12px;
  letter-spacing: 0.11px;
  line-height: 24px;
  margin-left: 14px;
}
.formnat-cv-responsive {
  height: 20px;
  color: rgba(0,0,0,0.30);
  font-family: Lato;
  font-size: 12px;
  letter-spacing: 0.11px;
  line-height: 24px;
  text-align: right;
  margin: 0px;
  margin-right: 2px;
}
.formnat-cv-responsive-error {
  height: 20px;
  color:#FF7573;
  font-family: Lato;
  font-size: 12px;
  letter-spacing: 0.11px;
  line-height: 24px;
  margin: 0px;
  margin-left: 14px;
}
.save-text {
  color: #FFFFFF;
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.43px;
  line-height: 26px;
  text-align: center;
  margin-top: 15px;
  text-transform: initial;
}
.cancel-text {
  color: #30B0BF;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.3px;
  line-height: 24px;
  text-align: center;
  text-transform: initial;
  margin: 0px;
}
.delete-text {
  color: #FF5C58;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.3px;
  line-height: 24px;
  text-transform: initial;
  margin: 0px;
  margin-top: 3px;
}
.card-percentage-profile {
  box-sizing: border-box;
  border: 1px solid #FFB274;
  border-radius: 6px;
  background-color: rgba(255,178,116,0.04);
}
.title-percentage {
  height: 32px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  margin: 0px;
  margin-bottom: 5px;
}
.sub-title-percentage {
  height: 42px;
  color: rgba(0,0,0,0.6);
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
  margin: 0px;
  margin-bottom: 10px;
}
.sub-title-percentage-responsive {
  height: 57px;
  color: rgba(0,0,0,0.6);
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0.13px;
  line-height: 19px;
  margin: 0px;
}
.progress-percentege {
  height: 10px;
  border-radius: 10px;
  margin: 0px;
}
.number-percentage {
  height: 22px;
  width: 115px;
  color: #EE8146;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.1px;
  line-height: 22px;
  margin-top: 10px;
}
.number-percentage-responsive {
  height: 32px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 44px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 32px;
}
</style>
