<template>
  <v-select
    v-model="pickerValue"
    :disabled="contracts.length < 1"
    :loading="loading"
    :items="contracts"
    item-value="value"
    item-text="text"
    v-bind="$attrs"
    hide-selected
    clearable
    label="Tipo de empleo"
    nudge-bottom="60"
    class="inputs-profile"
    full-width
  >
    <template v-slot:no-data>
      <v-list-tile>
        <v-list-tile-content>
          <v-list-tile-title>
            No se encuentran resultados
          </v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
    </template>
  </v-select>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ContractPickerWorkExperience',
  props: {
    value: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      pickerValue: '',
      contracts: [],
      loading: false,
    };
  },
  watch: {
    pickerValue() {
      this.$emit('input', this.pickerValue);
    },
    value(newVal) {
      this.pickerValue = newVal;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.pickerValue = this.value;
      this.loadContracts();
    });
  },
  methods: {
    loadContracts() {
      this.loading = true;
      axios.get(this.$store.state.endpoints.offers.public.contracts)
        .then(resp => {
          this.contracts = resp.data;
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.inputs-profile {
  height: 42px;
  border: 1px solid rgba(0,0,0,0.23);
  border-radius: 4px;
  margin-top: 20px;
  padding-bottom: 60px;
}
</style>
