<template>
  <v-layout mt-4>
    <v-flex xs12>
      <v-card
        class="card-work-exp"
      >
        <v-container>
          <p
            :class="$vuetify.breakpoint.smAndUp
              ? 'title-card-work-exp' : 'title-card-work-exp-responsive'"
          >
            Educación
          </p>
          <template v-for="(chd,index) in chdList">
            <v-layout
              :key="chd.uuid"
              justify-space-between
              :style="$vuetify.breakpoint.smAndUp
                ? 'margin-left: 30px' : 'margin-left: 10px'"
            >
              <v-layout
                column
                wrap
              >
                <p
                  :class="$vuetify.breakpoint.smAndUp
                    ? 'title-work-exp' : 'title-responsive-work-exp'"
                >
                  {{ chd.institution }}
                </p>
                <p
                  :class="$vuetify.breakpoint.smAndUp
                    ? 'sub-title-work-exp' : 'subtitle-responsive-work-exp'"
                >
                  {{ chd.degree_name }}
                </p>
                <p
                  :class="$vuetify.breakpoint.smAndUp
                    ? 'dates-work-exp' : 'dates-responsive-work-exp'"
                >
                  {{ dateFormat(chd.start_date) }} - {{ dateFormat(chd.end_date) }}
                </p>
                <p
                  :class="$vuetify.breakpoint.smAndUp
                    ? 'location-work-exp' : 'location-responsive-work-exp'"
                >
                  {{ chd.location }}
                </p>
              </v-layout>
              <p
                v-if="$vuetify.breakpoint.smAndUp"
                class="editar"
                style="cursor: pointer; position: relative;"
                @click="openEditModal(chd)"
              >
                <v-btn
                  style="margin-right: 0px"
                  icon
                >
                  <v-icon
                    icon
                    color="#EE8146"
                    size="18"
                  >
                    fas fa-pen
                  </v-icon>
                </v-btn>
                Editar
              </p>
              <v-btn
                v-else
                style="margin-top: 30px"
                icon
                @click="openEditModal(chd)"
              >
                <v-icon
                  icon
                  color="#EE8146"
                  size="18"
                  small
                >
                  fas fa-pen
                </v-icon>
              </v-btn>
            </v-layout>
            <div
              :key="`${chd.uuid} - ${index}`"
              class="separator-work-exp"
            />
          </template>
          <v-layout
            class="card-content-work-exp"
            justify-center
            align-center
          >
            <v-btn
              color="#30B0BF"
              style="border-radius: 6px"
              large
              @click="createDialog = true"
            >
              <p class="save-text">
                Agregar educación
              </p>
            </v-btn>
          </v-layout>
        </v-container>
      </v-card>
    </v-flex>
    <v-dialog
      v-model="createDialog"
      persistent
      max-width="670px"
      class="elevation-0"
    >
      <v-card>
        <v-layout
          justify-end
          mb-2
        >
          <v-btn
            icon
            style="position: absolute"
            @click="onDialogCancel()"
          >
            <v-icon
              icon
            >
              fal fa-times
            </v-icon>
          </v-btn>
        </v-layout>
        <v-container>
          <v-layout>
            <p
              class="title-dialog-work-exp"
            >
              {{ `${isEditing ? 'Editar' : 'Agregar'} educación` }}
            </p>
          </v-layout>
          <v-layout
            row
            wrap
          >
            <v-flex
              xs12
            >
              <v-combobox
                v-model="institution"
                :items="institutions"
                item-text="institution"
                :search-input.sync="search"
                hide-selected
                label="Institución educativa"
                class="inputs-profile"
                full-width
                single
                :error-messages="institutionErrors"
              />
            </v-flex>
            <v-flex
              xs12
            >
              <DegreePicker
                v-model="degree"
                :country="candCountry"
                :pre-search="degreeName"
                :degree-error="degreeErrors"
                class="inputs-profile"
                full-width
              />
            </v-flex>
            <v-flex
              xs12
            >
              <v-text-field
                v-model="location"
                label="Ubicación"
                class="inputs-profile"
                full-width
                :error-messages="locationErrors"
              />
            </v-flex>
            <v-flex
              xs12
            >
              <v-menu
                ref="dateFromMenu"
                v-model="dateFromMenu"
                :close-on-content-click="false"
                :nudge-left="15"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dateFrom"
                    label="Fecha de inicio"
                    class="inputs-profile"
                    full-width
                    readonly
                    append-icon="far fa-calendar"
                    :error-messages="startDateErrors"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="dateFrom"
                  locale="es"
                  min="1900-01-01"
                  @change="dateFromMenu = false"
                />
              </v-menu>
            </v-flex>
            <v-flex
              xs12
            >
              <v-menu
                ref="dateToMenu"
                v-model="dateToMenu"
                :close-on-content-click="false"
                :nudge-left="15"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dateTo"
                    label="Fecha de finalización"
                    class="inputs-profile"
                    readonly
                    full-width
                    append-icon="far fa-calendar"
                    :error-messages="endDateErrors"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="dateTo"
                  locale="es"
                  @change="dateToMenu = false"
                />
              </v-menu>
            </v-flex>
          </v-layout>
          <template
            v-if="$vuetify.breakpoint.smAndUp"
          >
            <v-layout
              mt-5
            >
              <v-flex
                v-if="isEditing"
                xs6
              >
                <p
                  style="cursor: pointer;"
                  class="delete-text"
                  @click="deleteDialog = true"
                >
                  <v-btn
                    style="margin-right: 0px;"
                    icon
                  >
                    <v-icon
                      style="margin: 0px; margin-bottom: 5px"
                      icon
                      color="#FF5C58"
                      size="18"
                    >
                      far fa-trash-alt
                    </v-icon>
                  </v-btn>
                  Eliminar educación
                </p>
              </v-flex>
              <v-flex
                v-else
                xs6
              />
              <v-flex
                xs6
              >
                <v-layout
                  justify-end
                >
                  <v-btn
                    color="#30B0BF"
                    flat
                    large
                    @click="onDialogCancel()"
                  >
                    <p class="cancel-text">
                      Cancelar
                    </p>
                  </v-btn>
                  <v-btn
                    color="#30B0BF"
                    style="border-radius: 6px"
                    large
                    @click="saveDegree()"
                  >
                    <p class="save-text">
                      Guardar
                    </p>
                  </v-btn>
                </v-layout>
              </v-flex>
            </v-layout>
          </template>
          <template
            v-else
          >
            <v-layout
              row
              wrap
              mt-4
            >
              <v-flex
                xs12
              >
                <v-btn
                  color="#30B0BF"
                  flat
                  large
                  block
                  @click="onDialogCancel()"
                >
                  <p class="cancel-text">
                    Cancelar
                  </p>
                </v-btn>
              </v-flex>
              <v-flex
                xs12
              >
                <v-btn
                  color="#30B0BF"
                  style="border-radius: 6px"
                  large
                  block
                  @click="saveDegree()"
                >
                  <p class="save-text">
                    Guardar
                  </p>
                </v-btn>
              </v-flex>
              <v-flex
                v-if="isEditing"
                xs12
              >
                <v-btn
                  color="#30B0BF"
                  flat
                  large
                  block
                  @click="deleteDialog = true"
                >
                  <p class="delete-text">
                    Eliminar educación
                  </p>
                </v-btn>
              </v-flex>
            </v-layout>
          </template>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="deleteDialog"
      max-width="270px"
      class="elevation-0"
    >
      <v-card>
        <v-container>
          <v-layout
            row
            wrap
          >
            <v-flex
              xs12
              mb-3
            >
              <v-layout
                justify-center
              >
                <p
                  class="title-work-exp"
                  style="font-size: 20px"
                >
                  ¿Estás seguro?
                </p>
              </v-layout>
            </v-flex>
            <v-flex
              xs12
            >
              <v-layout
                justify-center
              >
                <v-btn
                  color="#30B0BF"
                  flat
                  large
                  @click="deleteDialog = false"
                >
                  <p
                    class="cancel-text"
                  >
                    Cancelar
                  </p>
                </v-btn>
                <v-btn
                  color="#30B0BF"
                  style="border-radius: 6px"
                  large
                  @click="deleteChd()"
                >
                  <p class="save-text">
                    Sí
                  </p>
                </v-btn>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/es';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';
import DegreePicker from '@/components/inputs/DegreePicker.vue';

export default {
  name: 'CandidateHasDegree',
  components: {
    DegreePicker,
  },
  mixins: [JWTConfigMixin],
  props: {
    chdList: {
      type: Array,
      required: true,
    },
    candCountry: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      createDialog: false,
      degree: '',
      dateFromMenu: false,
      dateFrom: '',
      dateToMenu: false,
      dateTo: '',
      isEditing: false,
      degreeName: '',
      chdUuid: '',
      institution: '',
      location: '',
      deleteDialog: false,
      institutions: [],
      search: null,
      fieldErrors: null,
      startDateError: null,
      endDateError: null,
    };
  },
  computed: {
    degreeErrors() {
      return (this.fieldErrors ? this.fieldErrors.degree : []);
    },
    institutionErrors() {
      return (this.fieldErrors ? this.fieldErrors.institution : []);
    },
    locationErrors() {
      return (this.fieldErrors ? this.fieldErrors.location : []);
    },
    startDateErrors() {
      return (this.startDateError ? 'Ingrese una fecha de inicio.' : '');
    },
    endDateErrors() {
      return (this.endDateError ? 'Ingrese una fecha de finalización.' : '');
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadInstitutions();
    });
  },
  methods: {
    dateFormat(chd) {
      return moment(chd)
        .locale('es')
        .format('YYYY');
    },
    loadInstitutions() {
      axios.get(this.$store.state.endpoints.candidates.institution, this.jwtConfig)
        .then(resp => {
          this.institutions = resp.data;
          console.log(resp.data, 'INSTITUCIONES');
        });
    },
    randLetter() {
      let letters = 'abcdefghijklmnñopqrstuvwxyz0123456789-.,;:_!#$%&()=';
      letters = letters.replace(this.degreeName, '');
      return letters[Math.round(Math.random() * letters.length)];
    },
    onDialogCancel() {
      this.degreeName = '';
      this.dateFrom = '';
      this.dateTo = '';
      this.chdUuid = '';
      this.institution = '';
      this.location = '';
      this.isEditing = false;
      this.fieldErrors = null;
      this.startDateError = null;
      this.endDateError = null;
      this.createDialog = false;
    },
    openEditModal(chd) {
      this.isEditing = true;
      this.degreeName = chd.degree_name;
      this.dateFrom = chd.start_date;
      this.dateTo = chd.end_date;
      this.institution = chd.institution;
      this.location = chd.location;
      this.chdUuid = chd.uuid;
      this.createDialog = true;
    },
    deleteChd() {
      const url = this.$store.state.endpoints.candidates.changeCHD(this.chdUuid);
      axios.delete(url, this.jwtConfig)
        .then(() => {
          this.$store.commit('adminSnackbarUp', 'Estudio eliminado con éxito.');
          this.deleteDialog = false;
          this.onDialogCancel();
          this.$emit('input');
        });
    },
    saveDegree() {
      const payload = {};
      payload.degree = this.degree;
      payload.start_date = this.dateFrom;
      payload.end_date = this.dateTo;
      payload.institution = this.search;
      payload.location = this.location;
      payload.uuid = this.chdUuid;

      if (this.isEditing) {
        axios.patch(this.$store.state.endpoints.candidates.changeCHD(this.chdUuid),
          payload, this.jwtConfig)
          .then(() => {
            this.$store.commit('adminSnackbarUp', 'Estudio editado con éxito.');
            this.onDialogCancel();
            this.$emit('input');
          }).catch(err => {
            this.fieldErrors = err.response.data;
            this.startDateError = err.response.data.start_date;
            this.endDateError = err.response.data.end_date;
          });
      } else {
        axios.post(this.$store.state.endpoints.candidates.getCHD,
          payload, this.jwtConfig)
          .then(resp => {
            this.$store.commit('adminSnackbarUp', resp.data);
            this.degreeName = this.randLetter();
            this.dateFrom = '';
            this.dateTo = '';
            this.institution = '';
            this.location = '';
            this.createDialog = false;
            this.fieldErrors = null;
            this.startDateError = null;
            this.endDateError = null;
            this.$emit('input');
          }).catch(err => {
            this.fieldErrors = err.response.data;
            this.startDateError = err.response.data.start_date;
            this.endDateError = err.response.data.end_date;
          });
      }
    },
  },
};
</script>

<style scoped>
.editar {
  color: #EE8146;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.3px;
  line-height: 20px;
  margin-right: 25px;
  margin-top: 30px;
  position: absolute;
}
.inputs-profile {
  height: 42px;
  border: 1px solid rgba(0,0,0,0.23);
  border-radius: 4px;
  margin-top: 20px;
  padding-bottom: 60px;
}
.save-text {
  color: #FFFFFF;
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.43px;
  line-height: 26px;
  text-align: center;
  margin-top: 15px;
  text-transform: initial;
}
.cancel-text {
  color: #30B0BF;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.3px;
  line-height: 24px;
  text-align: center;
  text-transform: initial;
  margin: 0px;
}
.delete-text {
  color: #FF5C58;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.3px;
  line-height: 24px;
  text-transform: initial;
  margin: 0px;
  margin-top: 3px;
}
.card-work-exp {
  border-radius: 6px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.11);
}
.title-card-work-exp {
  height: 32px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: 15px;
  margin-bottom: 30px;
  margin-left: 20px;
}
.title-card-work-exp-responsive {
  height: 32px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: 15px;
  margin-bottom: 30px;
  margin-left: 10px;
}
.card-content-work-exp {
  box-sizing: border-box;
  height: 130px;
  border: 1px solid #E0E0E0;
  border-radius: 6px;
  background-color: rgba(238,238,238,0.21);
}
.title-dialog-work-exp {
  height: 32px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  margin: 0px;
}
.title-work-exp {
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.15px;
  line-height: 32px;
  margin: 0px;
}
.sub-title-work-exp {
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 22px;
  margin: 0px;
}
.dates-work-exp {
  color: rgba(0,0,0,0.6);
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 22px;
  margin: 0px;
  text-transform: capitalize;
}
.location-work-exp{
  height: 22px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 22px;
}
.separator-work-exp {
  height: 1px;
  transform: scaleY(-1) rotate(180deg);
  background-color: #E9E8ED;
  margin-bottom: 10px;
}

.title-responsive-work-exp {
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.1px;
  line-height: 32px;
  margin: 0px;
}
.subtitle-responsive-work-exp{
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 13px;
  letter-spacing: 0.09px;
  line-height: 22px;
  margin: 0px;
}
.dates-responsive-work-exp{
  color: rgba(0,0,0,0.6);
  font-family: Lato;
  font-size: 13px;
  letter-spacing: 0.09px;
  line-height: 22px;
  margin: 0px;
  text-transform: capitalize;
}
.location-responsive-work-exp {
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 13px;
  letter-spacing: 0.09px;
  line-height: 22px;
}
</style>
