<template>
  <v-autocomplete
    v-model="pickerValue"
    :search-input.sync="search"
    :items="items"
    item-text="name"
    item-value="uuid"
    v-bind="$attrs"
    label="Título/Grado"
    no-data-text="Escribe para buscar..."
    hide-selected
    :error-messages="degreeError"
  />
</template>

<script>
import axios from 'axios';

export default {
  name: 'DegreePicker',
  props: {
    country: {
      type: String,
      default: 'CL',
    },
    preSearch: {
      type: String,
      default: '',
    },
    degreeError: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      search: null,
      items: [],
      pickerValue: '',
    };
  },
  watch: {
    preSearch(nVal) {
      if (nVal.length > 5) {
        axios.get(this.$store.state.endpoints.candidates.degrees(this.country, nVal))
          .then(res => {
            this.items = res.data;
            this.items.forEach(el => {
              if (el.name === nVal) {
                this.pickerValue = el.uuid;
              }
            });
          })
          .catch(() => {
            this.items = [];
          });
      } else {
        this.items = [];
        this.pickerValue = '';
      }
    },
    pickerValue() {
      this.$emit('input', this.pickerValue);
    },
    search() {
      if (!this.search || this.search.length < 5) {
        this.items = [];
        this.pickerValue = '';
      } else {
        axios.get(this.$store.state.endpoints.candidates.degrees(this.country, this.search))
          .then(res => {
            this.items = res.data;
          })
          .catch(() => {
            this.items = [];
          });
      }
    },
  },
};
</script>
